import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/emergency-relief/humanitarian_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/emergency-relief/humanitarian_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/emergency-relief/humanitarian_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/emergency-relief/humanitarian_card_img4@2x.jpg';
import CardImg5 from '@/assets/img/emergency-relief/humanitarian_card_img5@2x.jpg';
import WedoIco1 from '@/assets/img/emergency-relief/humanitarian_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/emergency-relief/humanitarian_obj1_2@2x.png';
import WedoIco3 from '@/assets/img/emergency-relief/humanitarian_obj1_3@2x.png';
import WedoIco4 from '@/assets/img/emergency-relief/humanitarian_obj1_4@2x.png';
import WedoIco5 from '@/assets/img/emergency-relief/humanitarian_obj1_5@2x.png';
import WedoIco6 from '@/assets/img/emergency-relief/humanitarian_obj1_6@2x.png';
import WedoIco7 from '@/assets/img/nodiscrimination/childprotection_obj2020_5@2x.png';
import Obj from '@/assets/img/emergency-relief/humanitarian_obj@2x.png';
import ObjM from '@/assets/img/emergency-relief/humanitarian_objM@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import { SectionWedo } from '@/components/EmergencyRelief';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import ProcessList from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `효과적이고 신속하게 구호물품 지원`,
    dec: `유니세프는 세계 요충지에 위치한 유니세프 구호 물자창고를 이용해 72시간 내로 구호 물품을 전달하고, 정부와 긴밀하게 협력해 정확한 상황 파악과 대응을 합니다. 구호 물품에는 식수와 위생 키트, 교육 키트, 예방접종 의약품 등 위기 상황에 처한 어린이가 필요한 물품들이 포함되어 있습니다. 또한, 긴급 상황 이전, 도중, 이후에도 어린이가 건강하고 안전하게 지낼 수 있도록 지속적인 모니터링과 근본적인 지원을 합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `인도주의 상황에서 교육`,
    dec: `분쟁과 질병 창궐 같은 긴급상황에서도 어린이들이 계속 배우는 것은 매우 중요합니다. 유니세프는 어린이에게 기본 정규 교육뿐만 아니라 직장을 구할 수 있도록 직업 교육, 스킬 교육 등을 제공합니다. 어린이는 이러한 교육을 바탕으로 자신이 속한 지역사회와 국가의 회복을 돕는 주체적인 존재로 성장할 수 있게 됩니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `인도주의 상황에서 보건`,
    dec: `긴급 상황이 발생하면 유니세프는 관련 정부, 기관, 국제단체와 협력하여, 어린이가 건강하게 자라는데 필요한 필수적인 의료 서비스가 끊기지 않도록 합니다. 간이 진료실을 포함한 병원을 구축하고, 지역 의료진을 교육하며, 각종 의약품과 의료 기구를 보급합니다.`,
    img: CardImg3,
  },
  {
    id: 4,
    title: `안전한 식수와 위생`,
    dec: `긴급상황, 특히 전쟁 시에는 수원지 오염, 수도관 파괴 등으로 인해 어린이가 깨끗한 물을 마시기 어렵습니다. 유니세프는 물 트럭 배송, 수도관 신규 건설, 정화 시스템 구축, 임시 화장실 설치, 개인위생 용품 보급을 합니다. 이로써 어린이들이 수인성 질병에 걸리지 않고 깨끗한 환경에서 지낼 수 있도록 지원합니다.`,
    img: CardImg4,
  },
  {
    id: 5,
    title: `인도주의 상황에서 어린이 보호`,
    dec: `분쟁, 자연재해 등 긴급상황에서 어린이들은 다양한 폭력에 노출되어 있습니다. 도망치는 와중에 가족과 헤어지고 무장단체에 납치되기도 하며, 성폭력을 당하기도 합니다. 유니세프는 이를 막기위해 관련 정부 및 기관과 협력하여 어린이를 보호합니다. 어린이 보호 관련 법규를 강화하고, 어린이 보호 감시 시스템을 구축하며, 무장 단체로부터 어린이의 해방을 돕습니다. 가족을 잃은 어린이의 재결합을 돕고, 마음의 상처를 입은 어린이를 위해 심리치료를 제공합니다.`,
    img: CardImg5,
  },
];

const wedo = [
  {
    id: 1,
    tit: `2020년 주요 성과`,
    col: `col3`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `중증 영양실조에 걸린 <br />어린이 <strong>150만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `<strong>340만 명</strong> 어린이 홍역 예방 접종`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>1,420만 명</strong>이  <br />
            깨끗한 물에 접근하도록 도움`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco4,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>150만 명</strong>에게 심리사회 치료 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `어린이 <strong>240만 명</strong>에게 <br />정규, 비정규 교육 제공`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco6,
        desc: [
          {
            dec: `빈곤가정 <br />
            <strong>66만 7,000명</strong>에게 <br />
            현금 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2021년 주요 성과`,
    col: `col3`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `중증 영양실조에 걸린 <br />어린이 <strong>240만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `유니세프 지원 의료 시설에서 어린이와 가족 <strong>2,240만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>3,400만 명</strong>에게  <br />
            깨끗한 물 공급`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco4,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>500만 명</strong>에게 심리사회 치료 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `어린이 <strong>1억 1천만 명</strong>에게 <br />정규, 비정규 교육 제공`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco6,
        desc: [
          {
            dec: `빈곤가정 <br />
            <strong>1,490만 명</strong>에게 <br />
            현금 지원`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2022년 주요 성과`,
    col: `col3`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `중증 영양실조에 걸린 <br />어린이 <strong>260만 명</strong> 치료`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco2,
        desc: [
          {
            dec: `어린이 <strong>230만 명</strong>에게<br/>홍역 예방 접종`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>2,590만 명</strong>에게  <br />
            깨끗한 물 공급`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco4,
        desc: [
          {
            dec: `어린이와 가족 <br />
            <strong>1,300만 명</strong>에게 심리사회 치료 제공`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `어린이 <strong>2,800만 명</strong>에게 <br />정규, 비정규 교육 제공`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco6,
        desc: [
          {
            dec: `여성 및 어린이 <strong>420만 명</strong>의<br/>성별 기반 폭력 위험 대응 및 예방`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2023년 주요 성과`,
    col: `col3`,
    child: [
      {
        id: 1,
        img: WedoIco1,
        desc: [
          {
            dec: `어린이와 가족 2,600만 명 이상에게 보호 서비스 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco7,
        desc: [
          {
            dec: `어린이 25만 명 이상에게 가족 재결합 및 돌봄 서비스 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco4,
        desc: [
          {
            dec: `가자 지구 어린이와 가족 54만 명에게 현금 지원`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco3,
        desc: [
          {
            dec: `내전으로 고통받는 수단 어린이 6백만 명에게 식수, 보건, 영양, 교육 등 긴급구호 서비스와 물품 지원`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco5,
        desc: [
          {
            dec: `전쟁으로 고통받는 우크라이나 어린이 130만 명에게 공식, 비공식 학습 지원`,
          },
        ],
      },
      {
        id: 6,
        img: WedoIco6,
        desc: [
          {
            dec: `우크라이나 어린이와 보호자 약 250만 명에게 심리 치료 서비스 제공`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;

const Humanitarian: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST12'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A004'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="인도주의 지원"
      description="for every child, humanitarian action"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 인도주의 위기 상황에서도{` `}
                  <br className="small-hide" />
                  안전하고 건강하게 자랄 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                긴급한 분쟁과 자연재해 등 재난 상황 발생 시 어린이는 큰 고통을
                받습니다. 인도주의적 상황에서 어린이는 질병, 영양실조, 폭력,
                어린이 노동 등에 매우 쉽게 노출되기 때문입니다. 유니세프는
                긴급한 위기 상황에서도 모든 어린이가 권리를 보장받을 수 있도록,
                신속하고 다양한 구호 활동을 진행합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="gbB6N2V0i0o" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2023년 유니세프 인도주의 지원 성과
                </Tit>
              </h2>
              <p className="header-dec">
                유니세프는 2023년 107개국 412건의 긴급상황에 대처했습니다. <strong>구호 서비스 17억 4,400만 달러 상당 및 구호 물품 35억 달러 상당을 지원</strong>했습니다.
              </p>
            </div>
            <Image pcSrc={Obj} mobileSrc={ObjM} />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                인도주의 지원을 위해, <br />전 세계 파트너와 함께 유니세프가 한
                일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A004" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Humanitarian;
